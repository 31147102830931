import React from 'react'
// import emoji from '../react-easy-emoji'
// import {CloudinaryContext, Image, Transformation} from 'cloudinary-react'
import PropTypes from 'prop-types'
import CartAddToCartButton from './CartAddToCartSimpleButton'
import MagGlass from './MagGlassIcon'
import makeCloudinaryUrlForPhoto from './makeCloudinaryUrlForPhoto'
import {Link} from 'react-router-dom'
import IconAddToBagSVG from './IconAddToBagSVG'
import {get} from 'lodash'


import './MenuCategoryListView.scss'
import { is } from 'core-js/core/object'
let MAX_ITEMS = 50

class AutoMenuCheckerCategoryListView extends React.Component {

  state = {
    showAll: true
  }
  showRestOfCategory= () => {
    this.setState({ showAll: true })
  }
  render() {

    let className="photos grid"
    let isGallery = false
    let items = this.props.items
    let disableOrder = this.props.disableOrder
    let noPhoto = this.props.noPhoto
    let altStyleA = this.props.altStyleA
    const settings = this.props.settings
    

    let tagsCss = this.props.tags ? (' ' + this.props.tags) : ''
    let itemsToDisplay = this.state.showAll ? items : items.slice(0,MAX_ITEMS)
    return (
      <div className={"checker-menu-category-list-view" + (altStyleA ? " alt-style-a" : "")}>

        {/* List/Grid of Items */}
        <div className={className + tagsCss}>
          {itemsToDisplay.map(
            i => <MenuItem isGallery={isGallery}
              settings={settings}
              disableOrder={disableOrder}
              altStyleA={altStyleA}
              key={i.id} {...i}
              noPhoto={noPhoto}
              openPhotoSwipeGallery={this.props.openPhotoSwipeGallery}
              width={this.props.width}></MenuItem>)}
        </div>



        </div>
    );
  }
}

export default AutoMenuCheckerCategoryListView

class MenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // console.log(name)
    let {altStyleA, noPhoto, id, name, photo, width='600', description, mandatory, price, originalPrice, modgroups, tags, settings, sameModgroupAs } = this.props
    const menuQuickUpdates = get(settings, 'menu', {})
    // console.log('menuQuickUpdates', menuQuickUpdates)
    if (sameModgroupAs) {

      console.log('same modgroup as', sameModgroupAs)
    }
    console.log('noPhoto', noPhoto)

    let cssExtras = ''
    let disableOrderText = '*not availalbe online'
    name = name || {}
    let chinese = name.zh
    let korean  = name.kr
    name = (name && name.en) || "untitled"
    let isHalal = name.toLowerCase().indexOf('halal') !== -1
    if (tags.indexOf('halal') !== -1) {
      isHalal = true
    }
    description = description && description.en
    let cssItemTags = tags ? " " + tags : ""
    if (isHalal) {
      cssItemTags += " halal"
    }
    

    let cloudinaryURL
    if (photo) {
      cloudinaryURL = makeCloudinaryUrlForPhoto(photo, {width})
    }

    let dineInOnly = (name.indexOf('Dine-In Only') !== -1)
    let disableOrder = this.props.disableOrder || dineInOnly
    if (menuQuickUpdates[id] && menuQuickUpdates[id].outOfStock === 'OUT_OF_STOCK') {
      disableOrder = true
      disableOrderText = '*OUT OF STOCK'
    }

    
    
    if (altStyleA) {
      const menuItemPhotoStyle = photo ? " has-photo" : " no-photo"
      if (photo) {
        cloudinaryURL = makeCloudinaryUrlForPhoto(photo, {height: 300})
      }

      return(
        <section
            className={"checker-menu-item" + menuItemPhotoStyle + cssItemTags}
            >
      
      
        <div className="content">
        <header className="item-header">
          {originalPrice ? <div className="discount-price">${originalPrice} </div> : null}
            {(true) ? <div className="item-price">
              {(price) ? '$' : ''}
              {price}
              
            
            </div> : null}
       
          <span
            id={'p_' + id}
            className="food-name">{name}</span>
          
        </header>
        <p className="checker-menu-item__description">
          {korean ? <span>{korean} ·</span> : ''} 
          {description}</p>
          
          

          {disableOrder ? <div style={{fontStyle: "italic", fontSize: '0.8em'}} className="dine-in-only"> {disableOrderText} </div> : null}
      </div>
 
        {cloudinaryURL && !noPhoto && <div
          className="checker-photo-container"
          // onClick={this.props.openPhotoSwipeGallery.bind(this, id)}
          >
            <Link
            to={disableOrder ? "#" : (`/products/${this.props.id}`)}
            >
              <img
                src={cloudinaryURL}
                alt={""}

              />
            </Link>
            {/* <MagGlass></MagGlass> */}
        </div>}
        
          {sameModgroupAs ? 
          <div className="same-mod-group-as">
            {`same mods as`}
            <span className="link-name">
            {`${sameModgroupAs.name.en}`}
            </span>
          </div>
          : <ModifierCheck modgroups={modgroups} />}
      </section>
      )
    }

    return (

        <section className={"checker-menu-item" + cssExtras}>
          {cloudinaryURL && !noPhoto && <div
            className="photo-container"
            onClick={this.props.openPhotoSwipeGallery.bind(this, id)}>
              <img
                src={cloudinaryURL}
                alt={""}
              />
              {/* <MagGlass></MagGlass> */}
          </div>}
        <div className="content">
          <header>
            <span
              id={'p_' + id}
              className="food-name">{name}</span>
          </header>
          <p className="menu-item__description">
            {korean ? <span>{korean} ·</span> : ''} 
            {description}</p>
        </div>
        <div className="all-prices">
       {originalPrice ? <div className="discount-price">${originalPrice} </div> : null}
          {!disableOrder &&
            <Link
              className="product-link"
              aria-label={`customize ${name} and add to cart`}
              title={`customize ${name} and add to cart`}
              to={`/products/${this.props.id}`}>
          
          
                <span className="plus">+</span> <span className="price">${price}</span>
          
              </Link>}
        </div>
          {disableOrder && <span className="price">${price}</span>}
        </section>

    );
  }
}


function ModifierCheck ({modgroups}) {
  if (modgroups.length === 0) {return null}
  
  return(
    <div className="mod-check">
      {modgroups.map((modgroup) => {
       return(
        <div className="check-modgroup">
          <span className="group-name">{modgroup.name} <span className="type">({modgroup.type || 'multiple'})</span></span>

          <div className="check-modifiers">
            {modgroup.modifiers.map((modifier) => {
              return(
                <div className="check-modifier">
                  <span className="check-modifier-name">{modifier.name || modifier.id}</span>
                  {Number(modifier.price) ? <span className="price">${modifier.price}</span> : null}
                </div>
              )
            })}
          </div>
        </div>
       )
      })}
      
    </div>
  )
}