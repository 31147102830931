const locations = [

  
  // {
  //   id: "pablitostacosburbank",
  //   link: "/hamburguetzaburbank",
  //   label: "Hamburguetza",
  //   type: "NEW",
  //   special: "@Burbank Restaurant",
  //   cashback: 10,
  //   // disabled: true,
  //   // openingDate: "Reopen Dec.28",
  //   address: ["3803 W Burbank", "Burbank, CA 91505"],
  //   phone: "(818) 861-7050",
  //   phoneLink: "+18188617050",
  //   hours: [
  //     // {day: 'Nov.4', hours: '5pm-12am'},
  //     { day: 'Mon-Thu', hours: '11am-9pm' },
  //     { day: 'Fri', hours: '11am-10pm' },
  //     { day: 'Sat', hours: '11am-10pm' },
  //     { day: 'Sun', hours: '11am-9pm' },
  //   ]
  // },
  {
    id: "pablitostacosburbank",
    link: "/burbank",
    label: "Burbank",
    type: "Restaurant",
    concepts: [
      {
        link: '/burbank',
        label: "Pablito's Tacos",
      },
      {
        link: '/hamburguetzaburbank',
        label: "Hamburguetza",
      }
    ],
    special: "Tuesday / Thursday Specials",
    cashback: 10,
    // disabled: true,
    // openingDate: "Reopen Dec.28",
    address: ["3803 W Burbank", "Burbank, CA 91505"],
    phone: "(818) 861-7050‬‬",
    phoneLink: "+18188617050",
    hours: [
      // {day: 'Nov.4', hours: '5pm-12am'},
      { day: 'Mon-Thu', hours: '11am-9pm' },
      { day: 'Fri', hours: '11am-10pm' },
      { day: 'Sat', hours: '11am-10pm' },
      { day: 'Sun', hours: '11am-9pm' },
    ]
  },

  {
    id: "pablitostacosnoho",
    link: "/noho",
    concepts: [
      {
        link: '/noho',
        label: "Pablito's Tacos",
      },
      {
        link: '/hamburguetza',
        label: "Hamburguetza",
      },
      {
        link: '/nohoPizza',
        label: "Pablito's Pizza",
      }
    ],
    cashback: 10,
    label: "NoHo",
    type: "Taco Stand @ Circus Liquor",
    special: "+Pizza, Birria, Burgers",
    address: ["5600 Vineland Ave", "North Hollywood"],
    phone: "(818) 966-8534‬",
    phoneLink: "+18189668534",
    hours: [
      
      { day: 'Mon-Sun', hours: '5pm-12am' },
      { day: 'Fri,Sat', hours: '5pm-1am' },
      // { day: 'Fri-Sat', hours: '5pm-3am' },
      // { day: 'Sun', hours: '5pm-12am' }
    ]
  },


  {
    id: "pablitostacosvannuys",
    link: "/vannuys",
    label: "Van Nuys",
    type: "Restaurant",
    special: "NOW OPEN!",
    cashback: 10,
    concepts: [
      {
        link: '/vannuys',
        label: "Pablito's Tacos",
      },
      {
        link: '/vannuyschicken',
        label: "Pablito's Chicken",
      }
    ],
    // cashback: 10,
    // disabled: true,
    openingDate: "NOW OPEN!",
    address: ["14519 Sherman Way", " Van Nuys, CA 91405"],
    phone: "(747)208-8898",
    phoneLink: "+17472088898",
    hours: [
      {day: 'Sun-Thurs', hours: '11am-9pm'},
      {day: 'Sat,Fri', hours: '11am-10pm'},
      // { day: 'Mon-Thu', hours: '11am-9pm' },
      // { day: 'Fri', hours: '11am-10pm' },
      // { day: 'Sat', hours: '11am-10pm' },
      // { day: 'Sun', hours: '11am-9pm' },
    ]
  },

// Noho (arts district) 
// 5156 Lankershim Blvd, North Hollywood, CA 91601 
// At La Jungla

  // {
  //   id: "pablitostacosnorth",
  //   link: "/popup",
  //   type: "Pop-Up",
  //   label: "NoHo II",
  //   special: "NEW location",
  //   address: ["4793 Vineland Ave", "North Hollywood, 91602"],
  //   phone: "818 679 0183",
  //   phoneLink: "+18186790183",
  //   className: 'pablitostacosburbank',
    
  //   hours: [      
  //     // {day: 'Sun-Thu', hours: '6pm-11pm'},
  //     {day: 'Tue-Sun', hours: '5pm-12am'},

  //   ]},


 
  
  
  

   
    // {
    //   id: "pablitostacosnoho",
    //   link: "/hamburguetza",
    //   cashback: 10,
    //   label: "Hamburguetza",
    //   type: "@NoHo Taco Stand",
    //   // special: "",
    //   address: ["5600 Vineland Ave", "North Hollywood"],
    //   phone: "(818) 966-8534‬",
    //   phoneLink: "+18189668534",
    //   hours: [
    //     // { day: 'Mon-Tue', hours: '10am-12am' },
    //     { day: 'Mon-Sun', hours: '5pm-12am' },
    //     { day: 'Fri,Sat', hours: '5pm-1am' },
    //     // { day: 'Fri-Sat', hours: '5pm-3am' },
    //     // { day: 'Sun', hours: '5pm-12am' }
    //   ]
    // },

    // {
    //   id: "pablitostacosnoho",
    //   link: "/nohoPizza",
    //   cashback: 10,
    //   label: "Pablito's Pizza",
    //   // type: "New Wood Fired Oven",
    //   // special: "Peruvian/Mexican Pizza",
    //   address: ["5600 Vineland Ave", "North Hollywood"],
    //   phone: "(818) 966-8534‬",
    //   phoneLink: "+18189668534",
    //   hours: [
    //     { day: 'Mon-Sun', hours: '5pm-12am' },
    //     { day: 'Fri,Sat', hours: '5pm-1am' },
    //     // { day: 'Fri-Sat', hours: '5pm-3am' },
    //     // { day: 'Sun', hours: '5pm-12am' }
    //   ]
    // },
    // {
    //   id: "pablitosworld",
    //   link: "https://pablitosworld.com",
    //   external: true,
    //   // cashback: 10,
    //   label: "Pablito's World",
    //   // type: "@ Fashion Square Car Wash",
    //   // special: "multiple concepts",
    //   // tax: "Temporary Popup",
    //   address: ["686 N Spring St", "Los Angeles"],
    //   className: 'pablitostacosburbank',
    //   phone: "(213) 372-5898",
    //   phoneLink: "+12133725898",
    //   disabled: true,
    //   openingDate: "closed",
    //   hours: [
    //     { day: 'Sun-Thu', hours: '11am-9pm' },
    //     { day: 'Fri, Sat', hours: '11am-3am' },
    //     // { day: 'Fri,Sat', hours: '5pm-12am' },
    //     // { day: 'Sunday', hours: '5pm-11pm' },
    //     // { day: 'Fri,Sat', hours: '5pm-1am' },
    //     // { day: 'Fri-Sat', hours: '5pm-3am' },
    //     // { day: 'Sun', hours: '5pm-12am' }
    //   ]
    // },

//     {
//       id: "pablitostacosnorth",
//       link: "/palmdale",
//       label: "Lot of 30th and Palmdale",
//       special: "Food Truck",
//       address: ["2825 E Palmdale Blvd", "Palmdale, CA 93550"],
//       phone: "18186790183",
//       phoneLink: "+18186790183",
//       className: 'pablitostacosburbank',
//       disabled: true,
//       openingDate: "closed",
// // 
//       // buttonLabel: "Pickup Only",
//       hours: [
//         // { day: 'We', hours: "will be back!"}
//         {day: 'Tue-Wue', hours: '5pm-11pm'},
        
//         {day: 'Thu-Sun', hours: '11am-11pm'},
//         // { day: 'Sun', hours: '10am-12am' },
//         // { day: 'Opening',   hours: 'Tuesday Jan 17' }
//       ]},

    

    // {
    //   id: "pablitostacosoaks",
    //   link: "/shermanoaks",
    //   cashback: 10,
    //   label: "North Hills",
    //   // type: "@ Fashion Square Car Wash",
    //   // special: "New Location!",
    //   tax: "*temporary popup",
    //   address: ["9100 Sepulveda Blvd", "North Hills"],
    //   className: 'pablitostacosburbank',
    //   phone: "818 679 0183",
    //   phoneLink: "+18186790183",
    //   // disabled: true,
    //   // openingDate: "open March 8 6pm",
    //   hours: [
    //     { day: 'Tue-Sun', hours: '5pm-12am' },
    //     // { day: 'Fri,Sat', hours: '5pm-12am' },
    //     // { day: 'Sunday', hours: '5pm-11pm' },
    //     // { day: 'Fri,Sat', hours: '5pm-1am' },
    //     // { day: 'Fri-Sat', hours: '5pm-3am' },
    //     // { day: 'Sun', hours: '5pm-12am' }
    //   ]
    // },


    // {
    //   id: "pablitostacosvannuys",
    //   link: "/vannuys",
    //   label: "Van Nuys",
    //   type: "Truck",
    //   special: "Breakfast burritos!",
    //   // special: "September 11: Closed",
    //   address: ["15303 Sherman way ", "North Hollywood"],
    //   phone: "(818) 619-9226",
    //   phoneLink: "+18186199226",
    //   // buttonLabel: "Pickup Only",
    //   hours: [
    //     {day: 'Mon', hours: '8am-10pm'},
    //     { day: 'Tue-Sun', hours: '8am-2pm' },
    //     // { day: 'Fri-Sun', hours: '12pm-12am' },
    //     // { day: 'Fri-Sat',   hours: '5pm-1am' },
    //     // { day: 'Sun',       hours: '5pm-12am' }
    //   ]},

  // {
  //   id: "pablitosmarket",
  //   link: "/market",
  //   label: "Grocery",
  //   type: "grocery & home essentials",
  //   special: "20% Rewards",
  //   // disabled: true,
  //   // openingDate: "Closed Today",
  //   address: ["3803 W Burbank", "Burbank, CA 91505"],
  //   phone: "(818) 861-7050‬‬",
  //   phoneLink: "+18188617050",
  //   hours: [
  //     // {day: 'Nov.4', hours: '5pm-12am'},
  //     { day: 'Mon-Thu', hours: '11am-9pm' },
  //     { day: 'Fri', hours: '11am-10pm' },
  //     { day: 'Sat-Sun', hours: '8am-10pm' },
  //     { day: 'Sun', hours: '8am-9pm' },
  //   ]
  // },


  // {
  //   id: "pablitostacosburbank",
  //   link: "/burbank",
  //   label: "Weekend All-Day Breakfast",
  //   type: "Burbank",
  //   // special: "Free Delivery!",
  //   // disabled: true,
  //   // openingDate: "Closed Today",
  //   address: ["3803 W Burbank", "Burbank, CA 91505"],
  //   phone: "(818) 861-7050‬‬",
  //   phoneLink: "+18188617050",
  //   hours: [
  //     // {day: 'Nov.4', hours: '5pm-12am'},
  //     { day: 'Sat, Sun', hours: 'see burbank hours' },
  //     // { day: 'Sun-Thu', hours: '11am-9pm' },
  //     // { day: 'Fri', hours: '11am-10pm' },
  //   ]
  // },




    // {
    //   id: "pablitostacosmoorpark",
    //   link: "/moorpark",
    //   // special: "open again!",
    //   // disabled: true,
    //   // openingDate: "Online Ordering Off Today",
    //   label: "Van Nuys Morning",
    //   address: ["15303 Sherman Way", "Van Nuys"],
    //   phone: "(818) 966-8529‬",
    //   phoneLink: "+18189668529",
    //   hours: [
    //     { day: 'Mon-Fri', hours: '10am-3:30pm' },
    //     // { day: 'Fri-Sat', hours: '5pm-1am' },
    //     // { day: 'Sun', hours: '5pm-12am' }
    //   ]},
   
      // {
      //   id: "pablitostacosvannuys",
      //   link: "/vannuys",
      //   label: "Van Nuys",
      //   // special: "Open Today!",
      //   special: "Free Delivery & 15% Cashback",
      //   address: ["15303 Sherman Way", "Van Nuys"],
      //   phone: "(818) 966-8532",
      //   phoneLink: "+18189668532",
      //   // buttonLabel: "Pickup Only",
      //   hours: [
      //     // {day: 'Nov.4', hours: '5pm-12am'},
      //     { day: 'Mon-Thurs', hours: '5pm-12am' },
      //     { day: 'Fri-Sat',   hours: '5pm-1am' },
      //     { day: 'Sun',       hours: '5pm-12am' }
      //   ]},
    // {
    //   id: "pablitostacoseagle",
    //   link: "/moorpark",
    //   // special: "fish & oyster options",
    //   disabled: true,
    //   openingDate: "Visit Us!",
    //   label: "Colorado",
    //   address: ["2200 Colorado Blvd", "Los Angeles"],
    //   phone: "(818) 966-8529‬",
    //   phoneLink: "+18189668529",
    //   hours: [
    //     { day: 'Tue-Thurs', hours: '5pm-12am' },
    //     { day: 'Fri-Sat', hours: '5pm-2am' },
    //     { day: 'Sun', hours: '5pm-12am' }
    //   ]},
    // {
    //   id: "pablitostacoseagle",
    //   link: "/moorpark",
    //   // special: "fish & oyster options",
    //   disabled: true,
    //   openingDate: "Visit Us!",
    //   label: "Eagle Rock",
    //   address: ["444 Eagle Rock Blvd", "Los Angeles"],
    //   phone: "(818) 619-9226",
    //   phoneLink: "+18186199226",
    //   hours: [
    //     { day: 'Tue-Thurs', hours: '6pm-12am' },
    //     { day: 'Fri-Sat', hours: '6pm-2am' },
    //     { day: 'Sun', hours: '6pm-12am' }
    //   ]},









    // {
    //   id: "pablitostacosvictory",
    //   link: "/victory",
    //   // special: "triple cashback",
    //   label: "Western Tire",
    //   disabled: true,
    //   openingDate: 'closed',
    //   phone: "(818)424-5545",
    //   phoneLink: "+18184245545",
    //   address: ["801 S. Victory Blvd.", "Burbank"],
    //   hours: [
    //     { day: 'Wed-Thu', hours: '7pm-12am' },
    //     { day: 'Fri-Sat', hours: '7pm-1am' },
    //     { day: 'Sat', hours: '5pm-1am' },
    //     { day: 'Sun', hours: '5pm-11pm' },
    //   ]},
    //   {
    //     id: "pablitostacosstudio",
    //     link: "/studio",
    //     label: "Studio City",
    //     disabled: true,
    //
    //     // special: "double cashback",
    //     address: ["10968 Ventura Blvd.", "Studio City"],
    //     phone: "(818) 966-8535",
    //     phoneLink: "+18189668535",
    //     openingDate: 'closed',
    //     hours: [
    //       { day: 'Tue-Thu', hours: '6pm-12am' },
    //       { day: 'Fri-Sat', hours: '6pm-2:30am' },
    //       { day: 'Sun', hours: '6pm-12am' },
    //     ]},

]

export default locations
